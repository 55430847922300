import pageHero01 from "../../public/images/advantages/img_01.jpg";
import pageHero02 from "../../public/images/advantages/img_02.jpg";
import pageHero03 from "../../public/images/advantages/img_03.jpg";
import pageHero04 from "../../public/images/advantages/img_04.jpg";
import pageHero05 from "../../public/images/advantages/img_05.jpg";
import pageHero06 from "../../public/images/advantages/img_06.jpg";
import pageHero07 from "../../public/images/advantages/img_07.jpg";
import pageHero08 from "../../public/images/advantages/img_08.jpg";
import pageHero09 from "../../public/images/advantages/img_09.jpg";
import pageHero10 from "../../public/images/advantages/img_10.jpg";

import advantages07Img from "../../public/images/advantages/advantages_07_content_img.png";

export const ADVANTAGES_IMAGES = [pageHero01, pageHero02, pageHero03, pageHero04, pageHero05, pageHero06, pageHero07, pageHero08, pageHero09, pageHero10];

export const ADVANTAGES_INFO = [
  {
    title: "Обучение онлайн или оффлайн",
    content: `
    <p>В&nbsp;&laquo;Высшей школе предпринимательства&raquo; студенты могут обучаться как оффлайн в&nbsp;здании колледжа, так и&nbsp;онлайн из&nbsp;любой точки мира с&nbsp;помощью образовательного портала и&nbsp;мобильного приложения &laquo;ВШП&raquo;.</p>
    <p>Независимо от&nbsp;выбранного формата, каждый студент сможет получить качественное образование и&nbsp;поддержку от&nbsp;кураторов и&nbsp;преподавателей, которые помогут раскрыть потенциал и&nbsp;добиться успеха в&nbsp;будущей профессии.</p>

    <h3>ОЧНАЯ ФОРМА</h3>
    <p>
    Предусматривает посещение аудиторных занятий, которые проводятся 5-6 раз в&nbsp;неделю.</p>
    <ul>
    <li>Постоянный личный контакт с&nbsp;преподавателями и&nbsp;другими студентами.</li>
    <li>Занятия проходят утром или днем&nbsp;&mdash; наиболее продуктивный временной промежуток для обучения. </li>
    <li>Молодые люди получают отсрочку от&nbsp;армии на&nbsp;время учебы.</li>
    <li>Иногородние студенты имеют право на&nbsp;место в&nbsp;общежитии.</li>
    <li>Полноценная студенческая жизнь со&nbsp;всеми ее&nbsp;активностями.</li>
    </ul>

    <h3>ЗАОЧНАЯ ФОРМА</h3>
    <p>Применяются дистанционные образовательные технологии, не&nbsp;требующие посещения аудиторных занятий.</p>
    <ul>
    <li>Студенты заочной формы могут совмещать учебу с&nbsp;работой, а&nbsp;значит раньше начинают строить карьеру и&nbsp;к&nbsp;моменту получения диплома уже имеют опыт работы.</li>
    <li>Стоимость обучения ниже, чем на&nbsp;очной.</li>
    <li>Возможность самостоятельно подбирать график обучения в&nbsp;зависимости от&nbsp;занятости.</li>
    <li>Возможность изучать сразу несколько специальностей.</li>
    </ul>
    `,
  },

  {
    title: "Современная материально-техническая база",
    content: `
    <p>Колледж имеет собственное здание и&nbsp;закрытую территорию под видеонаблюдением. В&nbsp;нем&nbsp;&mdash; более 35&nbsp;комфортных аудиторий, оборудованные всем необходимым для обеспечения учебного процесса: компьютерами, проекторами, электронными досками, справочной литературой и&nbsp;методическими материалами.</p>
    <p>В&nbsp;здании колледжа находятся:</p>
    <p>
    <strong>Аудитории</strong>
    <br />
    Большинство аудиторий оборудовано проекторами и&nbsp;электронными досками.
    </p>
    <p>
    <strong>Компьютерный класс</strong>
    <br />
    Комплекс располагает компьютерным классом, оснащенным новым оборудованием.
    </p>
    <p>
    <strong>Библиотека</strong>
    <br />
    Студенты колледжа пользуются электронной и&nbsp;классической библиотекой с&nbsp;уютным читальным залом.
    </p>
    <p>
    <strong>Спортивный зал</strong>
    <br />
    Занятия по&nbsp;физической культуре и&nbsp;спортивные мероприятия проходят в&nbsp;хорошо оборудованном спортивном зале.
    </p>
    <p>
    <strong>Столовая</strong>
    <br />
    Питание студентов колледжа организовано в&nbsp;столовой на&nbsp;первом этаже здания.
    </p>
    <p>
    <strong>Медицинский кабинет</strong>
    <br />
    В&nbsp;здании работает медицинский кабинет, в&nbsp;котором учащимся готовы оказать необходимую медицинскую помощь.
    </p>
    <p>
    <strong>Гардеробная</strong>
    <br />
    Студенты оставляют верхнюю одежду и&nbsp;сменную обувь, не&nbsp;переживая за&nbsp;сохранность вещей.
    </p>
    `,
  },

  {
    title: "Удобная платформа онлайн-обучения",
    content: `
    <p>У&nbsp;каждого студента &laquo;ВШП&raquo; есть личный кабинет, который находится на&nbsp;vshp.online&nbsp;&mdash; собственном онлайн-портале организации. </p>
    <p>Чем удобен личный кабинет:</p>
    <ol>
    <li>
    <strong>Программа обучения</strong>
    <br />
    Образовательным порталом пользуются не&nbsp;только студенты на&nbsp;удаленке, но&nbsp;и&nbsp;учащиеся очной формы. У&nbsp;каждого из&nbsp;них на&nbsp;главной странице профиля подгружена программа, прогресс обучения по&nbsp;которой можно отследить.
    </li>
    <li>
    <strong>Связь с&nbsp;куратором</strong>
    <br />
    Если у&nbsp;учащихся возникают вопросы по&nbsp;поводу обучения, их&nbsp;можно задать куратору в&nbsp;чате в&nbsp;личном кабинете. Файлы и&nbsp;сообщения сохраняются в&nbsp;системе. По&nbsp;переписке удобно искать потерянный файл или нужную информацию.
    </li>
    <li>
    <strong>Оплата обучения</strong>
    <br />
    После зачисления в&nbsp;&laquo;ВШП&raquo; в&nbsp;личном кабинете студента появляется график оплаты обучения. Он&nbsp;находится под учебным планом программы, на&nbsp;которой студент учится. Оплатить обучение тоже можно через личный кабинет.
    </li>
    <li>
    <strong>Качество обучения</strong>
    <br />
    Родители очников и&nbsp;заочников могут проконтролировать успеваемость ребенка. Внутри дисциплин учебных планов содержатся видеолекции, тесты и&nbsp;прочие материалы, которые необходимы студентам всех форм обучения. Для заочников это основной способ получить информацию, для очников&nbsp;&mdash; дополнительные материалы и&nbsp;повтор изученного на&nbsp;лекциях.
    </li>
    <li>
    <strong>Защита информации</strong>
    <br />
    Личный кабинет учащегося и&nbsp;родителя&nbsp;&mdash; это не&nbsp;только способ связи с&nbsp;куратором и&nbsp;выбор программы обучения, но&nbsp;и&nbsp;хранилище данных пользователя, которые защищены системой в&nbsp;соответствии с&nbsp;политикой конфиденциальности портала. Передача данных и&nbsp;копий документов в&nbsp;личном кабинете намного безопасней, чем в&nbsp;мессенджерах. Также в&nbsp;личном кабинете можно совершать звонки.
    </li>
    </ol>
    `,
  },

  {
    title: "Преподаватели с опытом работы в высших учебных заведениях",
    content: `
    <p>Залог успешной деятельности колледжа, его развития и&nbsp;процветания&nbsp;&mdash; работоспособный творческий коллектив. Все преподаватели отличаются высоким профессионализмом, имеют большой опыт работы, в&nbsp;том числе и&nbsp;в&nbsp;высших учебных заведениях, что положительно сказывается на&nbsp;уровне обучения студентов.</p>
    <p>Среди них:</p>
    <h3>Толкаченко Галина Львовна</h3>
    <p>Кандидат экономических наук, профессор, почетный работник высшего профессионального образования. Трудовой стаж&nbsp;&mdash; 39&nbsp;лет. Обладает званием &laquo;Почетный профессор Тверского государственного университета&raquo;.</p>
    <p>Создатель ряда авторских курсов по&nbsp;экономике и&nbsp;финансовому менеджменту, автор более 100 научных статей. Член редколлегии университетского журнала &laquo;Вестник ТвГУ&raquo; в&nbsp;серии &laquo;Экономика и&nbsp;управление&raquo;. Принимает участие в&nbsp;работе экспертных комиссий ТвГУ. </p>
    <h3>Царёва Наталья Евгеньевна</h3>
    <p>Кандидат экономических наук, доцент. Ежегодно участвует в&nbsp;международных и&nbsp;всероссийских научно-практических конференциях.</p>
    <p>Является независимым экспертом в&nbsp;работе аттестационной комиссии Управления федерального казначейства по&nbsp;Тверской области. Принимает участие в&nbsp;проведении университетских олимпиад и&nbsp;научных конкурсов.</p>
    <p>Автор научных статей по&nbsp;теме &laquo;Финансовые аспекты развития экономики России&raquo;. Аккредитованный преподаватель Swiss International Institute Lausanne&nbsp;&mdash; SIIL.</p>
    <h3>Ткачёв Павел Сергеевич</h3>
    <p>Кандидат философских наук, преподаватель института &laquo;Высшая школа предпринимательства&raquo; по&nbsp;направлению &laquo;Информационные системы и&nbsp;технологии&raquo; и&nbsp;колледжа по&nbsp;направлению &laquo;Информационные системы и&nbsp;программирование&raquo;, эксперт чемпионата WorldSkills.</p>
    `,
  },

  {
    title: "Востребованные специальности",
    content: `
    <p>Мир стремительно меняется, и&nbsp;рынок труда не&nbsp;отстает. Технологический прогресс, изменения в&nbsp;экономике и&nbsp;обществе как создают возможности для успешной карьеры, так и&nbsp;устанавливают новые требования к&nbsp;работникам. В&nbsp;этом контексте одним из&nbsp;главных факторов успеха в&nbsp;выборе образовательного учреждения становится его способность предоставить студентам навыки и&nbsp;знания, которые востребованы на&nbsp;современном рынке труда.</p>
    <p>Колледж &laquo;ВШП&raquo; предлагает получить образование в&nbsp;областях, где спрос на&nbsp;профессионалов остается высоким. </p>
    <p>Направления обучения:</p>
    <ul>
    <li>Банковское дело</li>
    <li>Юриспруденция</li>
    <li>Информационные системы и&nbsp;программирование</li>
    <li>Коммерция (по&nbsp;отраслям)</li>
    <li>Финансы</li>
    </ul>
    `,
  },

  {
    title: "Развитые связи с работодателями. Возможность практики и трудоустройства выпускников",
    content: `
    <p>Колледж тесно сотрудничает с&nbsp;будущими работодателями. В&nbsp;организациях-партнерах студенты проходят практику и&nbsp;приобретают необходимые знания и&nbsp;навыки для будущей работы по&nbsp;выбранной профессии.</p>
    <p>Прохождение практики организовано на&nbsp;базе организаций:</p>
    <ul>
    <li>ПАО Сбербанк,</li>
    <li>Банк &laquo;Открытие&raquo;,</li>
    <li>Страховая группа &laquo;Уралсиб&raquo;,</li>
    <li>Министерство социальной защиты Тверской области,</li>
    <li>Социальный фонд России.</li>
    </ul>
    <p>Многие компании предлагают студентам трудоустройство после практики&nbsp;&mdash; иногда даже не&nbsp;дожидаясь окончания ими обучения. Студенты &laquo;Высшей школы предпринимательства&raquo; могут совмещать работу с&nbsp;учебой и&nbsp;начать накапливать трудовой стаж деятельности уже в&nbsp;колледже, что дает им&nbsp;больше возможностей на&nbsp;рынке труда.</p>
    <p>Также студенты могут оформить самозанятость. Это прекрасный способ работать, не&nbsp;прерывая учебный процесс, и&nbsp;одновременно получать первый предпринимательский опыт, который пригодится в&nbsp;будущем.</p>
    `,
  },

  {
    title: "Возможность параллельного обучения в школе и колледже",
    content: `
    <p>Больше не&nbsp;нужно выбирать между продолжением обучения в&nbsp;школе и&nbsp;поступлением в&nbsp;колледж&nbsp;&mdash; в&nbsp;&laquo;Высшую школу предпринимательства&raquo; абитуриент может поступить на&nbsp;базе аттестата за&nbsp;9&nbsp;класс и&nbsp;начать получать профессию, обучаясь в&nbsp;10&nbsp;классе. </p>
    <p>Таким образом уже через 1&nbsp;год и&nbsp;10&nbsp;месяцев после окончания школы выпускник получит желаемую специальность и&nbsp;начнет строить карьеру.</p>
    <figure>
    <img className="page__header-cover" src=${advantages07Img} alt="" />
    </figure>
    `,
  },

  {
    title: "Ускоренное обучение в вузе после окончания колледжа",
    content: `
    <p>&laquo;Высшая школа предпринимательства&raquo;&nbsp;&mdash; это образовательный комплекс, который дает возможность получить знания на&nbsp;всех четырех ступенях образования. Это значит, что выпускники колледжа могут поступить в&nbsp;институт &laquo;ВШП&raquo; и&nbsp;учиться на&nbsp;год меньше.</p>
    <p>Некоторые вузы предоставляют возможность учиться по&nbsp;сокращенной программе только тем выпускникам колледжей, которые продолжают обучение по&nbsp;специальности или учатся смежной профессии. В&nbsp;институте &laquo;ВШП&raquo; специальность не&nbsp;имеет значения: например, выпускники направления &laquo;Банковское дело&raquo; могут поступить в&nbsp;институт, выбрав направление &laquo;Маркетинг&raquo;. Выбирайте профессию по&nbsp;душе!</p>
    <p>Ускоренное обучение&nbsp;&mdash; это выгодно. Оно экономит время, позволяет начать карьеру или продолжить образование на&nbsp;более высоком уровне раньше, достичь своих целей быстрее и&nbsp;эффективнее. Также оно экономит денежные средства&nbsp;&mdash; меньше времени, проведенного в&nbsp;институте, означает меньше затрат на&nbsp;оплату обучения, проживание и&nbsp;другие расходы, связанные с&nbsp;учебой.</p>
    `,
  },

  {
    title: "Высококачественное содержание учебных программ",
    content: `
    <p>Учебные программы колледжа разработаны с&nbsp;учетом современных тенденций и&nbsp;требований рынка труда. Это позволяет студентам &laquo;Высшей школы предпринимательства&raquo; получать актуальные знания и&nbsp;навыки для освоения востребованных профессий.</p>
    <p>Основная цель наших учебных программ&nbsp;&mdash; подготовить студентов к&nbsp;успешной карьере в&nbsp;желаемой профессиональной области. Поэтому программы уделяют особое внимание развитию практических навыков и&nbsp;предоставляют возможность прохождения практики в&nbsp;организациях-партнерах, которые зачастую предлагают студентам работу еще во&nbsp;время обучения.</p>
    `,
  },

  {
    title: "Фиксированная стоимость обучения",
    content: `
    <p>Для студентов колледжа &laquo;Высшая школа предпринимательства&raquo;, оплативших обучение в&nbsp;полном объеме, стоимость зафиксирована на&nbsp;весь период обучения. В&nbsp;договоре прописан каждый год обучения или семестр.</p>
    <p>Таким образом абитуриенты и&nbsp;студенты точно знают, сколько будет стоить их&nbsp;обучение, и&nbsp;не&nbsp;боятся неожиданных дополнительных денежных расходов при изменении стоимости программ.</p>
    `,
  },
];
